import config from "@/config";
import { get, del, post } from "@/requests"

export async function getChallengesData() {
    return get(`${config.BACK_IP}/challenge`)
}

export async function getPatientChallenges(id) {
    return get(`${config.BACK_IP}/patient/${id}/challenge`)
}

export async function getChallengesDataTypes() {
    return get(`${config.BACK_IP}/challenge_type`)
}

export async function createNewChallenge(newChallenge) {
    return post(`${config.BACK_IP}/challenge`, newChallenge)
}

export function deleteChallenge(id) {
    return del(`${config.BACK_IP}/challenge/${id}`)
}