<template>
  <CModal class="c-modal" :title="modal.title" color="principal" :show.sync="internalShowModal" :closeOnBackdrop="false">
      <p class="text-muted">
        <strong>{{ modal.title }}</strong>
      </p>
      <CForm>
        <CRow>
          <CCol sm="4">
            <select class="form-select" v-model="selectedOption" @change="getSelectedOption">
                <option :value="null">{{ modal.selectPlaceholder }}</option>
                <option v-for="(item, index) in selectItems" :key="index" :value="item.id">
                    {{ (item.title) ? (item.title) : item.name }}
                </option>
            </select>
          </CCol>
          <CCol sm="5">
            <CInput v-if="modal.inputType === 'text'" type="text"  v-model="assignInput" :description="modal.inputPlaceHolder" size="sm" enabled @keyup="checkFields()" />
            <CInput v-else v-model="assignInput" type="number" :description="modal.inputPlaceHolder" enabled value="0" min="0" size="sm"  @input="checkFields()" />
          </CCol>
          <CCol sm="2">
            <CButton class="btn btn-add" ref="modalButton" disabled @click="emitEvent(modal.event)">
              {{modal.buttonEventName}}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
      <div slot="footer">
        <CButton class="btn btn-secondary" @click="closeModal">
          Cerrar
        </CButton>
      </div>
      <CRow class="justify-content-center">
        <CCol v-if="counterAlert > 0" md="9" class="p-4">
          <CAlert color="danger" dismissible :show.sync="counterAlert" close-button>
            <strong>AVISO</strong>
            {{ errorMessage }}
            <CProgress :max="10" :value="counterAlert" height="3px" color="danger" animated />
          </CAlert>
        </CCol>
      </CRow>
    </CModal>
</template>

<script>
export default {
  name: 'AssignModal',
  props: {
    showModal: {
        type: Boolean,
        required: true,
    },
    modal: {
        type: Object,
        required: true,
        default: {
          title: 'Título del modal',
          event: 'evento',
          inputType: 'text',
          inputPlaceHolder: 'Item',
          selectPlaceholder: 'Selecciona item',
          buttonEventName: 'Botón'
        }
    },
    errorText: {
        type: String,
        required: true,
        default: 'Mensaje de error'
    },
    selectItems: {
        type: Array,
        required: true,
    },
    contAlert: {
        type: Number,
        required: true,
        default: 0
    }
  },
  data(){
    return{
        selectedOption: null,
        assignInput: '',
        counterAlert: 0,
        internalShowModal: false,
        errorMessage: ''
    }
  },
  watch: {
    /**
     * Contador del tiempo en el que se muestra el mensaje de alerta.
     */
    contAlert(newValue) {
      this.counterAlert = newValue;
    },
    /**
     * Esta propiedad recoge el valor para mostrar el modal.
     */
    showModal(newVal) {
      this.internalShowModal = newVal;
    },
    /**
     * Con esta propiedad se recoge el nuevo valor del mensaje de error.
     */
    errorText(newValue) {
      this.errorMessage = newValue;
    },
  },
  methods: {
    /**
     * Método para saber que option del select se ha seleccionado y envía el valor al componente padre con emit.
     */
    getSelectedOption(){
        this.$emit('selectedOption', this.selectedOption);
    },
    /**
     * Método para comprobar los inputs del modal.
     */
    checkFields() {
      const button = this.$refs.modalButton;
      if (this.assignInput.length > 0) {
        button.disabled = false;
        button.style.opacity = "1";
      } else {
        button.disabled = true;
        button.style.opacity = ".5";
      }
    },
    /**
     * Evento que se emite al padre al pulsar el botón, recoge el nombre del método de manera dinámica
     */
    emitEvent(eventName){
        this.$emit(eventName, this.assignInput);
    },
    /**
     * Método que envía un emit al padre para poder cerrar el modal.
     */
    closeModal() {
      this.$emit('closeModal', false);
    },
  }
}
</script>

<style>
.c-modal .close{
    visibility: hidden;
}
.alert-danger .close{
  visibility: visible;
}
</style>