<template>
    <div>
        <Loader v-if="loading"></Loader>
        <div v-else>
            <CCard>
                <CCardHeader>
                    {{ title }}
                </CCardHeader>
                <CCardBody>
                    <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="userFields" :canEdit="canEdit" />
                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader>
                    <p v-if="!enableLimits">Limites actuales</p>
                    <p v-else>Asignar limites</p>
                </CCardHeader>
                <CCardBody>
                    <div class="patients-limits">
                        <CRow>
                            <CCol sm="5">
                                <CInput id="minimumLimit" type="number" label="Limite mínimo" horizontal min="0"
                                    :value="minimumLimit" v-model="minimumLimit" :disabled="isAdmin" />
                            </CCol>
                            <CCol sm="5">
                                <CInput id="maximumLimit" type="number" label="Limite máximo" horizontal min="0"
                                    :value="maximumLimit" v-model="maximumLimit" :disabled="isAdmin" />
                            </CCol>
                            <CCol v-if="!isAdmin" sm="2">
                                <CButton class="btn btn-add" style="margin-right: 10px" @click="addLimits()">Asignar</CButton>
                                <CButton v-if="!enableLimits" class="btn btn-delete" @click="deleteLimits()">
                                    <CIcon name="cil-trash" />
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow class="justify-content-center">
                            <CCol v-if="contAlertLimits > 0" md="9" class="p-4">
                                <CAlert color="danger" dismissible :show.sync="contAlertLimits" close-button>
                                    <strong>AVISO</strong>
                                    {{ errorTextLimits }}
                                    <CProgress :max="10" :value="contAlertLimits" height="3px" color="danger" animated />
                                </CAlert>
                            </CCol>
                            <CCol v-if="successLimits > 0" md="9" class="p-4">
                                <CAlert color="success" dismissible :show.sync="successLimits" close-button>
                                    <strong>AVISO</strong>
                                    {{ successTextLimits }}
                                    <CProgress :max="10" :value="successLimits" height="3px" color="success" animated />
                                </CAlert>
                            </CCol>
                        </CRow>
                    </div>
                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader>
                    <p v-if="this.patientPathologies.length > 0">Patologías y consejos actuales</p>
                    <p v-else>Asignar patologías</p>
                    <div class="button-container">
                        <CButton v-if="!isAdmin" class="link-button" @click="modalPathologies = true" style="margin-right: 1em">
                            Asignar patologías
                        </CButton>
                    </div>
                </CCardHeader>
                <CCardBody v-if="this.patientPathologies.length > 0">
                    <div class="patient-pathologies">
                        <div class="pathology-box" v-for="(patologia, index) of this.patientPathologies" :key="index">
                            <h5> Patología: <span>{{ patologia.name }}</span></h5>
                            <CButton :id="'pathology_section_' + index" class="btn-secondary toggle-pathology-button"
                                @click="togglePathologySection()">
                                Mostrar
                            </CButton>
                            <CButton v-if="!isAdmin" class="btn btn-danger active delete-button btn-delete" enabled
                                @click="deletePathology(patologia.id)">
                                <CIcon name="cil-trash" />
                            </CButton>
                            <div class="pathology-inner-box" :data-type="'pathology_section_' + index">
                                <ul>
                                    <li>
                                        <strong>Descripción:</strong>
                                        <span>{{ patologia.description ? patologia.description : " Sin descripción" }}</span>
                                    </li>
                                    <li>
                                        <strong>Comentario del doctor:</strong>
                                        {{ patologia.comment ? patologia.comment : " Sin comentario" }}
                                    </li>
                                </ul>
                                <div class="related-advice" v-for="(advice, index) of patologia.advices" :key="index">
                                    <h6>Consejo: <span>{{ advice.title }}</span></h6>
                                    <ul>
                                        <li>
                                            <strong>Descripción: </strong>
                                            <span>{{ advice.description }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCardBody>
                <CCardBody v-else>
                    <p>No hay patologías asignadas al paciente, pulse el botón 'Asignar patologías' se desea asignar alguna
                        patología
                        al paciente.</p>
                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader>
                    <p v-if="this.patientChallenges.length > 0">Retos actuales</p>
                    <p v-else>Asignar retos</p>
                    <div class="button-container">
                        <CButton v-if="!isAdmin" class="btn link-button" @click="modalChallenges = true" style="margin-right: 1em">
                            Asignar retos
                        </CButton>
                    </div>
                </CCardHeader>
                <CCardBody v-if="this.patientChallenges.length > 0">
                    <div class="patient-challenges">
                        <div v-for="(challenge, index) of this.patientChallenges" :key="index">
                            <CRow>
                                <CCol sm="10">
                                    <CInput :id="'campo_' + challenge.id_challenge" description="Límite"
                                        :label="challenge.title" :value="challenge.limit" horizontal size="sm" disabled />
                                </CCol>
                                <CCol v-if="!isAdmin" sm="2">
                                    <CButton class="btn btn-delete" enabled @click="deleteChallenge(challenge.id_challenge)">
                                        <CIcon name="cil-trash" />
                                    </CButton>
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </CCardBody>
                <CCardBody v-else>
                    <p>No hay retos asignados al paciente, pulsa el botón 'Asignar retos' si desea asignar algún reto al paciente.</p>
                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader>
                    <p v-if="this.patientsAlerts.length > 0">Alertas actuales</p>
                    <p v-else>Asignar alertas</p>
                    <div class="button-container">
                        <CButton v-if="!isAdmin" class="btn link-button" @click="modalAlerts = true" style="margin-right: 1em">
                            Asignar alertas
                        </CButton>
                    </div>
                </CCardHeader>
                <CCardBody v-if="this.patientsAlerts.length > 0">
                    <div v-for="(alert, index) of patientsAlerts" :key="index">
                        <CRow>
                            <CCol sm="10">
                                <CInput :id="'campo_' + alert.id_alert" description="Límite" :label="alert.title"
                                    :value="alert.alert_limit" horizontal size="sm" disabled />
                            </CCol>
                            <CCol v-if="!isAdmin" sm="2">
                                <CButton class="btn btn-delete" enabled @click="deleteAlert(alert.id_alert)">
                                    <CIcon name="cil-trash" />
                                </CButton>
                            </CCol>
                        </CRow>
                    </div>
                </CCardBody>
                <CCardBody v-else>
                    <p>No hay alertas asignadas al paciente, pulsa el botón 'Asignar alertas' si desea asignar alguna alerta al
                        paciente.</p>
                </CCardBody>
            </CCard>
            <AssignModal @closeModal="closeModal" @assignPathology="assignPathology" @selectedOption="getPathologyId"
                :modal="pathologiesModal" :showModal="modalPathologies" :selectItems="pathologies"
                :errorText="errorTextPathology" :contAlert="contAlertPathology" />

            <AssignModal @closeModal="closeModal" @assignChallenge="assignChallenge" @selectedOption="getChallengeId"
                :modal="challengesModal" :showModal="modalChallenges" :selectItems="challenges" :errorText="errorTextChallenge"
                :contAlert="contAlertChallenge" />

            <AssignModal @closeModal="closeModal" @assignAlert="assignAlert" @selectedOption="getAlertId" :modal="alertsModal"
                :showModal="modalAlerts" :selectItems="alerts" :errorText="errorTextAlert" :contAlert="contAlerts" />
        </div>
    </div>
</template>

<script>
import { getPatientData, getPacientLimit, getPacientAlert } from "@/services/patients";
import { getChallengesData, getPatientChallenges } from "@/services/challenges";
import { getPathologiesData, getPatientPathologies } from "@/services/pathologies";
import { getAlertsData } from "@/services/alerts";
import { updatePatient, assignPatientPathology, assignPatientAlert, addPatientLimits, addPatientChallenge, deletePatientPathology, deletePatientChallenge, deletePatientAlert, deletePatientLimits } from "@/services/patients";
import AssignModal from '@/components/Modals/AssignModal.vue';
import AbstractForm from '@/components/Forms/AbstractForm.vue';
import Loader from "@/components/Loader.vue";

export default {
    name: "editPatient",
    components: { AssignModal, AbstractForm, Loader },
    data(){
      return{
        title: null,
        id: null,
        LIMIT: 99999999,
        loading: true,
        isAdmin: false,
        activeButton: false,
        canEdit: false,
        pathologyIDSelection: null,
        alertIDSelection: null,
        challengeIDSelection: null,
        modalPathologies: false,
        modalChallenges: false,
        modalAlerts: false,
        enableLimits: true,
        pathologies: [],
        alerts: [],
        patientPathologies: [],
        patientsAlerts: [],
        challenges: [],
        patientChallenges: [],
        minimumLimit: 0,
        maximumLimit: 0,
        contAlertPathology: 0,
        contAlertChallenge: 0,
        contAlertLimits: 0,
        contAlerts: 0,
        successLimits: 0,
        successTextLimits: "",
        errorTextPathology: "",
        errorTextChallenge: "",
        errorTextLimits: "",
        errorTextAlert: "",
        formContent: {
            buttons: [
                {
                    textButton: 'Volver',
                    color: 'secondary',
                    event: 'back',
                    style: 'margin: 10px',
                    active: true,
                },
                {
                    textButton: 'Editar',
                    color: 'success',
                    event: 'edit',
                    style: 'margin: 10px',
                    active: !this.$store.state.isAdmin
                },
            ],
            rows: [
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'name',
                                label: 'Nombre',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'surname',
                                label: 'Apellidos',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'email',
                                label: 'Email',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'date',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'date',
                                value: '',
                                key: 'birthdate',
                                label: 'Fecha de nacimiento',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'select',
                                value: '',
                                key: 'gender',
                                label: 'Género',
                                maxLength: 30,
                                options:
                                    [
                                        { name: 'Hombre', value: "male" },
                                        { name: 'Mujer', value: "female" },
                                        { name: 'Otros', value: "other" }
                                    ],
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'textArea',
                                value: '',
                                key: 'description',
                                label: 'Descripcion',
                                maxLength: 1000,
                            },
                        },
                    ]
                },
                {
                    style: [],
                    class: [],
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: [],
                            class: [],
                            content: {
                                type: 'input',
                                value: '',
                                key: 'weight',
                                label: 'Peso',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: [],
                            class: [],
                            content: {
                                type: 'input',
                                value: '',
                                key: 'height',
                                label: 'Altura',
                                maxLength: 30,
                            },
                        },
                    ]
                },
            ],
        },
        userFields: {
            name: null,
            surname: null,
            email: null,
            birthdate: null,
            gender: null,
            description: null,
            weight: null,
            height: null
        },
        genders: [
            { name: "Seleccione un género", value: "none-gender" },
            { name: "Hombre", value: "male" },
            { name: "Mujer", value: "female" },
            { name: "Otros", value: "other" },
        ],
        pathologiesModal: {
            title: 'Asignar Patologías',
            event: 'assignPathology',
            inputType: 'text', selectPlaceholder: 'Selecciona patología',
            inputPlaceHolder: 'Descripción',
            buttonEventName: 'Asignar'
        },
        challengesModal: {
            title: 'Asignar retos',
            event: 'assignChallenge',
            inputType: 'number',
            selectPlaceholder: 'Selecciona un reto',
            inputPlaceHolder: 'Límite',
            buttonEventName: 'Asignar'
        },
        alertsModal: {
            title: 'Asignar alertas',
            event: 'assignAlert',
            inputType: 'number',
            selectPlaceholder: 'Selecciona una alerta',
            inputPlaceHolder: 'Límite',
            buttonEventName: 'Asignar'
        }
      }
    },
    created() {
        this.isAdmin = this.$store.state.isAdmin;
        this.receiveData();
    },
    methods: {
        onEmitMethods(event, dataPatient) {
            switch (event) {
                case 'edit': this.editPatient(dataPatient); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        receiveData() {
            this.id = this.$route.params.id;
            if (this.id) {
                this.getPatient();
                this.getPathologies();
                this.getChallenges();
                this.getAlerts();
                this.getPatientPathologiesData();
                this.getPatientChallengesData();
                this.getPacientAlertsData();
            }
        },
        /**
         * Función para validar inputs del formulario
         */
        checkInputs(dataPatient) {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const numericRegex = /^[0-9]+$/; // Expresión regular para validar números enteros
            const validationRules = [
                { field: 'name', message: 'Introduzca el nombre (máximo de 30 caracteres).', maxLength: 30 },
                { field: 'surname', message: 'Introduzca el apellido (máximo de 20 caracteres).', maxLength: 20 },
                { field: 'email', message: 'Introduzca un email válido.', regex: emailRegex },
                { field: 'birthdate', message: 'Introduzca la fecha de nacimiento.' },
                { field: 'gender', message: 'Seleccione un género.', value: '' },
                { field: 'description', message: 'Introduzca una descripción (máximo de 1000 caracteres).', maxLength: 1000 },
                { field: 'weight', message: 'Introduzca el peso en kilogramos (sólo valores enteros).', regex: numericRegex, minValue: 0 },
                { field: 'height', message: 'Introduzca la altura en centímetros (sólo valores enteros).', regex: numericRegex, minValue: 0 },
            ];
            for (const rule of validationRules) {
                const value = dataPatient[rule.field];
                if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataPatient[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        /**
         * Método para mostrar y ocultar las patologías
         */
        togglePathologySection() {
            const pathologyToShow = event.target.id;
            const currentPathologySection = `[data-type=${pathologyToShow}]`;
            const section = document.querySelector(currentPathologySection);

            if (section.style.display == "" || section.style.display == "none") {
                document.getElementById(`${pathologyToShow}`).innerHTML = "Ocultar";
                section.style.display = "block";
            } else {
                document.getElementById(`${pathologyToShow}`).innerHTML = "Mostrar";
                section.style.display = "none";
            }
        },
        /**
         * Método para poder actualizar los datos del paciente.
        */
        editPatient(dataPatient) {
            if (this.checkInputs(dataPatient) == false) return

            updatePatient(dataPatient, this.id)
            .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
            .then(() => {
                this.successText = "El paciente se ha editado correctamente.";
                this.$router.push("/patient")
            });
        },
        /**
         * Función para obtener los datos del paciente en cuestión
        */
        getPatient() {
            if (!this.id) return;
            let data = getPatientData(this.id);
            data
            .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
            .then((result) => {
                if (localStorage.token == undefined) return;

                this.userFields.name = result.name;
                this.userFields.surname = result.surname;
                this.userFields.email = result.email;
                this.userFields.birthdate = result.birthdate;
                this.userFields.description = result.description;
                this.userFields.weight = result.weight;
                this.userFields.height = result.height;
                this.pacientGenderValue(result.gender);
                if (result.min_limit || result.max_limit) {
                    this.getLimits();
                }
                this.title = "Información del paciente " + this.userFields.name + " " + this.userFields.surname;
                this.loading = false;
            })
        },
        /**
         * Función genérica para obtener datos
         */
        getItems(functionName, apiFunction) {
          apiFunction(this.id)
            .catch((error) => {
                this.$store.state.errorAlert = { status: true, msg: error.toString() };
            })
            .then((result) => {
                this[functionName] = result;
                this.loading = false;
            });
        },
        /**
         * Función en la que se obtienen las patología
         *
         * @returns {object[]} Las patologías obtenidas.
         */
        getPathologies() {
            this.getItems('pathologies', getPathologiesData);
        },
       /**
         * Función en la que se obtienen los retos
         * @returns {object[]} Los retos obtenidas.
        */
        getChallenges() {
            this.getItems('challenges', getChallengesData);
        },
        /**
         * Función en la que se obtienen las alertas y que también
         * llama el metodo receiveData para cargarlos.
         *
         * @returns {object[]} Las alertas obtenidas.
         */
        getAlerts() {
            this.getItems('alerts', getAlertsData);
        },
        /**
         * Función para obtener los limites del paciente.
        */
        getLimits() {
            getPacientLimit(this.id)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                .then((result) => {
                    this.minimumLimit = result.min_limit;
                    this.maximumLimit = result.max_limit;
                    this.enableLimits = !(this.maximumLimit !== 0 || this.minimumLimit !== 0);
                    this.loading = false;
                });
        },
        /**
         * Función para obtener las patologías del paciente.
        */
        getPatientPathologiesData() {
            this.getItems('patientPathologies', getPatientPathologies);
        },
        /**
         * Función para obtener los retos del paciente.
        */
        getPatientChallengesData() {
            this.getItems('patientChallenges', getPatientChallenges);
        },
        /**
         * Función para obtener las alertas del paciente.
        */
        getPacientAlertsData() {
            this.getItems('patientsAlerts', getPacientAlert);
        },
        /**
         * Método para poder obtener el genero del paciente y preseleccionarlo en el Select
        */
        pacientGenderValue(patient_gender) {
            const gender = this.genders.find((i) => i.value == patient_gender);
            this.userFields.gender = gender.value;
        },
        /**
         * Función para obtener la patología seleccionada.
        */
        getPathologyId(option) {
            this.pathologyIDSelection = option;
        },
        /**
         * Función para obtener el reto seleccionado.
        */
        getChallengeId(option) {
            this.challengeIDSelection = option;
        },
        /**
         * Función para obtener la alerta seleccionada.
        */
        getAlertId(option) {
            this.alertIDSelection = option;
        },
        /**
         * Función para añadir limites al paciente.
        */
        addLimits() {

            const minimumLimit = parseInt(this.minimumLimit);
            const maximumLimit = parseInt(this.maximumLimit);

            if (minimumLimit < 0 || maximumLimit < 0) {
                this.contAlertLimits = 10;
                this.errorTextLimits = "Los campos no pueden ser negativos";
                return;
            } else if (minimumLimit == maximumLimit) {
                this.contAlertLimits = 10;
                this.errorTextLimits = "El límite mínimo no puede ser igual que el límite máximo";
                return;
            } else if (minimumLimit > maximumLimit) {
                this.contAlertLimits = 10;
                this.errorTextLimits = "El límite mínimo no puede ser mayor que el límite máximo";
                return;
            } else if (minimumLimit > 99999 || maximumLimit > 99999) {
                this.contAlertLimits = 10;
                this.errorTextLimits = "Uno de los límites es demasiado alto";
                return;
            } else {
                this.contAlertLimits = 0;
                this.errorTextLimits = "";
            }
            addPatientLimits(this.id, minimumLimit, maximumLimit)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                .then(() => {
                    this.getLimits();
                    this.successLimits = 5;
                    this.successTextLimits = "Se han asignado los limites correctamente.";
                });
        },
        /**
         * Función para asignar patologías al paciente.
        */
        assignPathology(value) {

            let exist = false;

            if (this.pathologyIDSelection == null) {
                this.setErrorAndAlert('Selecciona una patología.', 'errorTextPathology', 'contAlertPathology');
            }else if (value == "") {
                this.setErrorAndAlert('Introduzca una descripción.', 'errorTextPathology', 'contAlertPathology');
            }else if (value.length > 100) {
                this.setErrorAndAlert('La descripción es demasiado larga.', 'errorTextPathology', 'contAlertPathology');
            }else{
                this.contAlertPathology = 0;
                this.patientPathologies.forEach((pathology) => {
                    if (pathology.id == this.pathologyIDSelection) {
                        this.setErrorAndAlert('Esa patología ya está asignada.', 'errorTextPathology', 'contAlertPathology');
                        exist = true;
                    }
                });

                if (exist == true) return

                assignPatientPathology(this.id, this.pathologyIDSelection, value)
                    .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                    .then(() => {
                        this.getPatientPathologiesData();
                        this.modalPathologies = false;
                    });
            }

        },
        /**
         * Función para asignar retos al paciente.
         */
        assignChallenge(value) {

            let exist = false;

            if (this.challengeIDSelection == null) {
                this.setErrorAndAlert('Selecciona un reto.', 'errorTextChallenge', 'contAlertChallenge');
            }else if(value < 0) {
                this.setErrorAndAlert('El límite no puede ser negativo.', 'errorTextChallenge', 'contAlertChallenge');
            }else if(value > this.LIMIT) {
                this.setErrorAndAlert('El límite es demasiado alto.', 'errorTextChallenge', 'contAlertChallenge');
            } else {
                this.contAlertChallenge = 0;
                this.patientChallenges.forEach((challenge) => {
                    if (challenge.id == this.challengeIDSelection) {
                        this.setErrorAndAlert('Ese reto ya está asignado.', 'errorTextChallenge', 'contAlertChallenge');
                        exist = true;
                    }
                });

                if (exist == true) return

                addPatientChallenge(this.id, this.challengeIDSelection, value)
                    .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                    .then(() => {
                        this.getPatientChallengesData();
                        this.modalChallenges = false;
                    });
                }
        },
        /**
         * Función para asignar alertas al paciente.
         */
        assignAlert(value) {

            let exist = false;

            if (this.alertIDSelection == null) {
                this.setErrorAndAlert('Selecciona una alerta.', 'errorTextAlert', 'contAlerts');
            }else if(value < 0) {
                this.setErrorAndAlert('El límite no puede ser negativo.', 'errorTextAlert', 'contAlerts');
            }else if(value > this.LIMIT) {
                this.setErrorAndAlert('El límite es demasiado alto.', 'errorTextAlert', 'contAlerts');
            }else{
                this.contAlerts = 0;
                this.patientsAlerts.forEach((alert) => {
                    if (alert.id == this.alertIDSelection) {
                        this.setErrorAndAlert('Esa alerta ya está asignada.', 'errorTextAlert', 'contAlerts');
                        exist = true;
                    }
                });

                if (exist == true) return

                assignPatientAlert(this.id, this.alertIDSelection, value)
                    .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                    .then(() => {
                        this.getPacientAlertsData();
                        this.modalAlerts = false;
                    });
            }

        },
        /**
         * Función genérica para eliminar items.
         */
        deleteItem(id, itemId, deleteFunction, getDataFunction) {
            deleteFunction(id, itemId)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
                .then(() => {
                    getDataFunction();
                });
        },
        /**
         * Función para eliminar los limites del paciente.
         */
        deleteLimits() {
            this.deleteItem(this.id, null, deletePatientLimits, this.getLimits);
        },
        /**
         * Función para eliminar la patología del paciente.
         */
        deletePathology(idPatientPathology) {
            this.deleteItem(this.id, idPatientPathology, deletePatientPathology, this.getPatientPathologiesData);
        },
        /**
         * Función para eliminar el reto del paciente.
         */
        deleteChallenge(idPatientChallenge) {
            this.deleteItem(this.id, idPatientChallenge, deletePatientChallenge, this.getPatientChallengesData);
        },
        /**
         * Función para eliminar la alerta del paciente.
         */
        deleteAlert(idPatientAlert) {
            this.deleteItem(this.id, idPatientAlert, deletePatientAlert, this.getPacientAlertsData);
        },
        /**
         * Función genérica para mostrar el error.
        */
        setErrorAndAlert(errorText, errorTextType, contType){
            this[errorTextType] = errorText;
            this[contType] = 10;
            return;
        },
        /**
         * Función para cerrar el modal.
         */
        closeModal(value) {
            this.modalPathologies = value;
            this.modalChallenges = value;
            this.modalAlerts = value;
        },
        /**
         * Función para volver atrás
        */
        back() {
            this.$router.go(-1);
        }
    }
};
</script>

<style>
.patient-pathologies,
.patient-challenges {
    padding-top: 10px;
}

.card-header .button-container {
    float: right;
}

.card-header p {
    display: inline;
}

#modalPathology .modal-body,
#modalChallenges .modal-body {
    padding-top: 30px;
}

.patient-pathologies h5 span,
.patient-pathologies h6 span {
    font-weight: 400;
}

.patient-pathologies ul {
    position: relative;
}

.patient-pathologies ul li {
    padding: 4px 0;
}

.patient-pathologies strong {
    font-weight: 500;
    padding-right: 3px;
}

.patient-pathologies .delete-button {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 3;
}

.patient-pathologies .toggle-pathology-button {
    position: absolute;
    top: 12px;
    right: 70px;
    z-index: 3;
}

.patient-pathologies .pathology-box {
    border: 1px solid;
    border-radius: 0.25rem;
    background-color: #f1f1f1;
    border-color: #d8dbe0;
    padding: 20px;
    margin-bottom: 20px;
    padding-bottom: 0;
    position: relative;
}

.patient-pathologies .pathology-box .pathology-inner-box {
    display: none;
}

.patient-pathologies .pathology-box h5 {
    padding: 10px;
    padding-top: 0;
}

.patient-pathologies .related-advice {
    padding: 0 20px;
}

@media (max-width: 768px) {
    .patient-pathologies .pathology-box {
        padding: 20px 0;
    }

    .patient-challenges>div {
        margin-bottom: 10px;
    }

    .patient-pathologies .pathology-box .delete-button,
    .patient-pathologies .pathology-box .toggle-pathology-button {
        position: static;
        margin-left: 10px;
        margin-bottom: 14px;
        width: 44%;
        display: inline;
        text-align: center;
    }

    .card-header .button-container {
        float: left;
    }

    .modal-body .form-group {
        margin-top: 10px;
    }
}
</style>